import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import bgCover from "../../imgs/cover.jpg";
import { Link } from "react-router-dom";

function BlogDetails() {
    let [blog, setBlog] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        let id = window.location.href.split('id=').pop();
        console.log(id);
        axios.get('https://api-axopolitan.herokuapp.com/blogs/'+id)
            .then(res => {
                setBlog(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <>
            <div className="container">
                <div className="row">
                    {blog.length === 0 ? <><h1>Loading... </h1><br/> <span class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </span></> :
                        <div className="blogDetails">
                            <h1>{blog.heading}</h1>
                            <img src={blog.imgSrc} />
                            <p>{blog.text}</p>
                        </div>
                    }
                </div>
                <Link to="/blogs" className="btn detailsBtn m-2" style={{float: 'right'}}>Go to blog page</Link>
            </div>
            <Footer />
        </>
    );
}

export default BlogDetails;