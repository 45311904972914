import React, { useState } from "react";
import Footer from "../Layouts/Footer";
import { useEffect } from "react";
import Header from "../Layouts/Header";
import { Link } from "react-router-dom";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleShowTO = bol => {
    localStorage.setItem('showTO', JSON.stringify(bol));
  }

  return (
    <div>
      <div className="homeMain contactUs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mx-auto">
              <img src="/imgs/boy.png" className="w-100" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="convoBox">
                <h1>let's start a conversation</h1>
                <p>
                  Ready to experience the best move of your life? See how you
                  can use technology to move smarter every step of the way. Give
                  us a call at 07043748903 to tell us all about your move. You
                  can also talk about your hopes, dreams, desires,
                  whatever–we’re here to listen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="getInTouch py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 main">
              <h1>Get in touch</h1>
              <p>
                We're ready to quickly answer any questions or concerns. Please
                select your preferred method below!
              </p>
            </div>
          </div>
          <div className="row three">
            <div className="col-md-4 text-center">
              <img src="/imgs/talk.svg" alt="" />
              <h2>Talk to a Representative</h2>
              <p>
                Feel free to get in touch with us anytime, we'd be happy to
                respond quickly.
              </p>
              <button className="getCall">
                <a href="tel:07043748903" style={{color: "white"}}>
                  <div className="align-items-center">
                    Give us a call <i className="fa-solid fa-phone"></i>
                    {/* <span>07043748903</span> */}
                  </div>
                </a>
              </button>
            </div>
            <div className="col-md-4 text-center schedule mt-md-0 mt-5">
              <img src="/imgs/schedule.svg" alt="" />
              <h2>Schedule a consultation</h2>
              <p>
                Ready to Move now? Not to worry Our expert consultant would
                guide you through a seamless process.
              </p>
              <Link to="/get-a-quote"><button onClick={() => handleShowTO(true)}>
                Start now <i className="fa-solid fa-play"></i>
              </button></Link>
            </div>
            <div className="col-md-4 text-center questions mt-md-0 mt-5">
              <img src="/imgs/questions.svg" alt="" />
              <h2>Got questions? Let's call you</h2>
              <p>
                Want to know more about our moving and storage services? Not
                able to speak now. We'll call you!
              </p>
              <button>
              <a href="tel:07043748903" style={{color: "white"}}>
              <div className="align-items-center">
                Give us a call <i className="fa-solid fa-phone"></i>
                {/* <span>07043748903</span> */}
              </div>
            </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
