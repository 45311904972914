import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import bgCover from "../../imgs/cover.jpg";
import { Link } from "react-router-dom";

function Blogs() {
    let [blogs, setBlogs] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        axios.get('https://api-axopolitan.herokuapp.com/blogs')
            .then(res => {
                setBlogs(res.data)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <>
            <div className="homeMain blogs" style={{backgroundImage: `url(${bgCover})`}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Header />
                        </div>
                        <div className="col-12 my-auto searchMain">
                            <h1>Moving is a lot easier with the right knowledge</h1>
                            <p>
                            We’ve seen it all. Nourish yourself with some of our latest posts
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="row">
                    <h1>Recent Blogs</h1>
                </div>
                <div className="row">
                    {blogs.length === 0 ? <span class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </span> :
                        <>{blogs.map((elem, idx) => {
                            return <span key={idx} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 blog my-1">
                                <img src={elem.imgSrc} />
                                <h3 className="m-2" style={{float: 'left'}}>{elem.heading}</h3>
                                <Link to={`/blogDetails?id=${elem._id}`}><button className="btn m-2 detailsBtn">Read</button></Link>
                                {/* <p>{elem.text}</p> */}
                            </span>
                        })}
                        </>
                    }
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Blogs;