import React, { useEffect, useState } from "react";
import { Stepper } from "react-form-stepper";
import { Link } from "react-router-dom";
import Header from "../Layouts/Header";
import Form from "react-bootstrap/Form";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from 'axios';
import moment, { relativeTimeRounding } from "moment";
import $ from 'jquery';
import bgImg from '../../imgs2/steps.png';

function GetQuote() {
  const [currentstep, setCurrentstep] = useState(0);
  const [value, onChange] = useState(new Date());

  let [showTO, setShowTO] = useState(localStorage.getItem("showTO") || true);
  let [movingFrom, setMovingFrom] = useState(JSON.parse(localStorage.getItem('movingFromVal')) || '');
  let [movingTo, setMovingTo] = useState(JSON.parse(localStorage.getItem('movingToVal')) || '');
  let [homeSize, setHomeSize] = useState('');
  let [selectedDate, setSelectedDate] = useState();
  let [onsite, setOnsite] = useState(false);
  let [time, setTime] = useState('');
  let [firstName, setFirstName] = useState('');
  let [lastName, setLastName] = useState('');
  let [email, setEmail] = useState('');
  let [contactNo, setContactNo] = useState('');
  let [howDidHear, setHowDidHear] = useState('0');
  let [additionalInfo, setAdditionalInfo] = useState('');
  let [error1, setError1] = useState('');
  let [error2, setError2] = useState('');
  let [error3, setError3] = useState('');
  let [error4, setError4] = useState('');
  let [showGoBackBtn, setShowGoBackBtn] = useState(false);
  let [showLoader, setShowLoader] = useState(false);
  let [address, setAddress] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentstep(0);
  }, []);

  const handleNext = () => {
    if (currentstep === 0) {
      if (movingFrom === "" || movingTo === "") {
        setError1('Please fill all the fields.');
      }
      else {
        setError1('');
        setCurrentstep(1);
      }
      localStorage.removeItem('movingFromVal');
      localStorage.removeItem('movingToVal');
    }
    if (currentstep === 1) {
      if (homeSize.length === 0) setError2('Please select your room size.');
      else {
        setCurrentstep(2);
        setError2("");
      }
    }
    if (currentstep === 2) {
      if (showTO === "true") {
        if (selectedDate === "" || selectedDate === undefined || time === "" || onsite === false || address === "") setError3('Please select all the fields.');
        else {
          setError3("");
          setCurrentstep(3);
        }
      } else if (showTO === "false") {
        if (selectedDate === "" || selectedDate === undefined) setError3('Please select a date.');
        else {
          setError3("");
          setCurrentstep(3);
        }
      }

    }
  }

  const handleSubmit = () => {
    if (firstName === "" || lastName === "" || email === false || contactNo === "" || additionalInfo === "" || howDidHear === "0") {
      setError4('All fields are required. Please fill all the fields.');
      return;
    }
    let validEmail = validateEmail(email);
    if (!validEmail) {
      setError4('Please enter a valid email.');
      return;
    }

    let validContactNo = validateContactNo(contactNo);
    if (!validContactNo) {
      setError4('Please enter a valid contact number.');
      return;
    }

    setShowLoader(true);
    if (showTO === 'true') {
      let obj = { movingFrom, movingTo, homeSize, visitDate: moment(selectedDate).format(), modeOfVisit: onsite, address, visitTime: time, firstName, lastName, email, phoneNumber: contactNo, howYouHearAboutUs: howDidHear, additionalDetails: additionalInfo };
      postConsultation(obj);
    } else if (showTO === "false") {
      let obj = { movingFrom, movingTo, homeSize, movingDate: moment(selectedDate).format(), firstName, lastName, email, phoneNumber: contactNo, howYouHearAboutUs: howDidHear, additionalDetails: additionalInfo };
      postQoute(obj);
    }
  }

  const validateEmail = input => {
    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (input.match(validRegex)) return true;
    else return false;
  }

  const validateContactNo = contactNo => {
    var reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return reg.test(contactNo);
  }

  const postConsultation = obj => {
    const headers = {
      'Authorization': 'Bearer base64:LGCVHWAkwbMMqSt6j5FQBpGXGGxs/D8huidrrhZ+GPI=',
      'dev_mode': 'production'
    }

    axios.post('https://api.axopolitan.com/api/v1/survey', obj, {
      headers: headers
    })
      .then((res) => {
        console.log(res.data);
        setShowGoBackBtn(true);
        document.getElementById('tobeClicked').click();
        setShowLoader(false);
        clearForm();
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const postQoute = obj => {
    // axios.post('https://api-axopolitan.herokuapp.com/get-a-qoute', obj)
    // .then(res => {
    //   console.log(res.data);
    //   setShowGoBackBtn(true);
    //   document.getElementById('tobeClicked').click();
    //   setShowLoader(false);
    //   clearForm();
    // })
    // .catch(err => {
    //   console.log(err);
    // })
    const headers = {
      'Authorization': 'Bearer base64:LGCVHWAkwbMMqSt6j5FQBpGXGGxs/D8huidrrhZ+GPI=',
      'dev_mode': 'production'
    }

    axios.post('https://api.axopolitan.com/api/v1/free-quote', obj, {
      headers: headers
    })
      .then((res) => {
        console.log(res.data);
        setShowGoBackBtn(true);
        document.getElementById('tobeClicked').click();
        setShowLoader(false);
        clearForm();
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const clearForm = () => {
    setMovingFrom('');
    setMovingTo('');
    setHomeSize('');
    setSelectedDate('');
    setTime('');
    setOnsite(false);
    setAddress("");
    setFirstName('');
    setLastName('');
    setEmail('');
    setContactNo('');
    setHowDidHear("0");
    setAdditionalInfo('');
  }

  const handleHomeSize = () => {
    let size = "";
    for (let i = 0; i <= 4; i++) {
      let val = document.getElementById('bedroom' + i);
      if (val.checked) {
        size = val.value;
        document.getElementById("others").value = "";
      }
    }
    setHomeSize(size);
  }

  const handleInput = () => {
    for (let i = 0; i <= 4; i++) {
      let val = document.getElementById('bedroom' + i);
      if (val.checked) val.checked = false;
    }
    let val = document.getElementById('others').value;
    setHomeSize(val)
  }

  const handleTime = () => {
    let arr = [];
    for (let i = 1; i <= 6; i++) {
      let val = document.getElementById('time' + i);
      if (val.checked) arr.push(val.value);
    }
    setTime(arr.toString());
  }

  const handleOnsite = () => {
    let val = "";
    let onsite = document.getElementById('on-site');
    let virtually = document.getElementById('virtually');
    if (onsite.checked) val = onsite.value;
    else if (virtually.checked) val = virtually.value;
    setOnsite(val);
  }

  const handleDateChange = value => {
    setSelectedDate(value);
    // setCalendarText(`The selected Date is ${value.toDateString()}`);
  };

  useEffect(() => { setAddress(""); console.log(onsite) }, [onsite])

  return (
    <>
      <div className="homeMain steps" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
            </div>
            <div className="col-12 py-4">
              <div className="stepperMain">
                {/* <Stepper
                  stepClassName={"step"}
                  steps={[
                    { label: "" },
                    { label: "" },
                    { label: "" },
                    { label: "" },
                  ]}
                  activeStep={currentstep}
                /> */}
                <div className="stepper">
                  <div
                    className={`step ${currentstep === 0
                      ? "active"
                      : currentstep > 0
                        ? "completed"
                        : ""
                      }`}
                  >
                    1
                  </div>
                  <div
                    className={`connector ${currentstep === 1
                      ? "active"
                      : currentstep > 1
                        ? "active"
                        : ""
                      }`}
                  ></div>
                  <div
                    className={`step ${currentstep === 1
                      ? "active"
                      : currentstep > 1
                        ? "completed"
                        : ""
                      }`}
                  >
                    2
                  </div>
                  <div
                    className={`connector ${currentstep === 2
                      ? "active"
                      : currentstep > 2
                        ? "active"
                        : ""
                      }`}
                  ></div>
                  <div
                    className={`step ${currentstep === 2
                      ? "active"
                      : currentstep > 2
                        ? "completed"
                        : ""
                      }`}
                  >
                    3
                  </div>
                  <div
                    className={`connector ${currentstep === 3 ? "active" : ""}`}
                  ></div>
                  <div className={`step ${currentstep === 3 ? "active" : ""}`}>
                    4
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9 mx-auto">
                    {currentstep === 0 ? (
                      <div className="content">
                        <h1>Where are you moving?</h1>
                        <p>
                          We just need a few details from you to get you a free
                          quote.
                        </p>
                        <div className="seperator"></div>
                        <form className="py-5">
                          <input
                            type="text"
                            placeholder="Moving From"
                            className="mb-3"
                            value={movingFrom} onChange={e => setMovingFrom(e.target.value)} onInput={() => setError1("")}
                          />
                          <input type="text" placeholder="Moving To"
                            value={movingTo} onChange={e => setMovingTo(e.target.value)} onInput={() => setError1("")} />
                        </form>
                        <div className="seperator"></div>
                        <div className="text-end">
                          <span className="error">{error1}</span>
                          <button
                            className="arrowBtn mt-3 me-0"
                            style={{ width: "120px" }}
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    ) : currentstep === 1 ? (
                      <div className="content">
                        <h1>What is your home size?</h1>
                        <p>
                          This ensures we're rightly prepared for your move.
                        </p>
                        <form className="py-5 checkboxContainer" id="form">
                          <Form.Check
                            type="radio"
                            label="Studio"
                            value="Studio"
                            id="bedroom0"
                            className="mb-3"
                            name="time_range"
                            onChange={handleHomeSize}
                          />
                          <Form.Check
                            type="radio"
                            label="1 Bedroom"
                            value="1 Bedroom"
                            id="bedroom1"
                            className="mb-3"
                            name="time_range"
                            onChange={handleHomeSize}
                          />
                          <Form.Check
                            type="radio"
                            label="2 Bedroom"
                            value="2 Bedroom"
                            id="bedroom2"
                            className="mb-3"
                            name="time_range"
                            onChange={handleHomeSize}
                          />
                          <Form.Check
                            type="radio"
                            label="3 Bedroom"
                            value="3 Bedroom"
                            id="bedroom3"
                            className="mb-3"
                            name="time_range"
                            onChange={handleHomeSize}
                          />
                          <Form.Check
                            type="radio"
                            label="4+ Bedroom"
                            value="4+ Bedroom"
                            id="bedroom4"
                            className="mb-3"
                            name="time_range"
                            onChange={handleHomeSize}
                          />
                          {/* <Form.Check
                            type="radio"
                            label="Studio"
                            value="Studio"
                            id="bedroom0"
                            className="mb-3"
                            onChange={handleHomeSize}
                          />
                          <Form.Check
                            type="radio"
                            label="1 Bedroom"
                            value="1 Bedroom"
                            id="bedroom1"
                            className="mb-3"
                            onChange={handleHomeSize}
                          // value={homeSize}
                          // onChange={e => setHomeSize(e.target.value)}
                          />
                          <Form.Check
                            type="radio"
                            label="2 Bedroom"
                            value="2 Bedroom"
                            id="bedroom2"
                            className="mb-3"
                            onChange={handleHomeSize}
                          />
                          <Form.Check
                            type="radio"
                            label="3 Bedroom"
                            value="3 Bedroom"
                            id="bedroom3"
                            className="mb-3"
                            onChange={handleHomeSize}
                          />
                          <Form.Check
                            type="radio"
                            label="4+ Bedroom"
                            value="4+ Bedroom"
                            id="bedroom4"
                            className="mb-3"
                            onChange={handleHomeSize}
                          /> */}
                          <input
                            type="text"
                            className="w-100 ms-4"
                            placeholder="others"
                            id="others"
                            onInput={handleInput}
                          />
                        </form>
                        <div className="text-end">
                          <span className="error">{error2}</span>
                          <button
                            className="arrowBtn mt-3 me-2"
                            style={{ width: "120px" }}
                            onClick={() => setCurrentstep(0)}
                          >
                            Back
                          </button>
                          <button
                            className="arrowBtn mt-3 me-0"
                            style={{ width: "120px" }}
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    ) : currentstep === 2 ? (
                      // <div className="content">
                      //   <h1>When is your move selectedDate?</h1>
                      //   <p>Almost there....You're really moving along.</p>
                      //   <Calendar onChange={onChange} value={value} />
                      //   <div className="text-end mt-5">
                      //     <button
                      //       className="arrowBtn mt-3 me-2"
                      //       style={{ width: "120px" }}
                      //       onClick={() => setCurrentstep(1)}
                      //     >
                      //       Back
                      //     </button>
                      //     <button
                      //       className="arrowBtn mt-3 me-0"
                      //       style={{ width: "120px" }}
                      //       onClick={() => setCurrentstep(3)}
                      //     >
                      //       Next
                      //     </button>
                      //   </div>
                      // </div>
                      <div className="content">
                        {showTO === "true" ? <><h1>When can we pay a visit?</h1>
                        <p>
                          On-site or Virtual, Let us know when you'd like to
                          have the consultation with us.
                        </p></> : 
                        <><h1>When is your move date?</h1>
                        <p>
                          Almost there...Your're really moving along.
                        </p></>
                        }
                        <div className="row">
                          <div className={showTO === "true" ? 'col-md-5' : 'col-md-12'}>
                            <Calendar onChange={handleDateChange} value={selectedDate} />
                            {showTO === "true" && <form className="pt-5 d-flex gap-4 boldLabels">
                              <Form.Check
                                type="radio"
                                label="On Site"
                                value="On Site"
                                id="on-site"
                                className="mb-3"
                                name="onsite"
                                onChange={handleOnsite}
                              />
                              <Form.Check
                                type="radio"
                                label="Virtually"
                                value="Virtually"
                                id="virtually"
                                className="mb-3"
                                name="onsite"
                                onChange={handleOnsite}
                              />
                            </form>}
                            {onsite === "On Site" && <div>
                              <input type="text" className="form-control" placeholder="Enter address" value={address} onChange={e => setAddress(e.target.value)} />
                            </div>}
                            {onsite === "Virtually" && <div>
                              <select className="form-control" value={address} onChange={e => setAddress(e.target.value)}>
                                <option value="0">Select Option</option>
                                <option value="Google meet">Google meet</option>
                                <option value="WhatsApp video-call">WhatsApp video-call</option>
                                <option value="Facetime">Facetime</option>
                                <option value="Zoom">Zoom</option>
                              </select>
                            </div>}
                          </div>
                          <div className="col-md-2"></div>
                          {showTO === "true" && <div className="col-md-5">
                            <h3 className="selectRange">Select Time Range</h3>
                            <form className="py-2 d-flex flex-column align-items-center">
                              <Form.Check
                                type="radio"
                                label="8:00 - 10:00 AM"
                                value="8:00 - 10:00 AM"
                                id="time1"
                                className="mb-3"
                                name="time_range"
                                onChange={handleTime}
                              />
                              <Form.Check
                                type="radio"
                                label="10:00 - 12:00 PM"
                                value="10:00 - 12:00 PM"
                                id="time2"
                                className="mb-3"
                                name="time_range"
                                onChange={handleTime}
                              />
                              <Form.Check
                                type="radio"
                                label="12:00 - 02:00 PM"
                                value="12:00 - 02:00 PM"
                                id="time3"
                                className="mb-3"
                                name="time_range"
                                onChange={handleTime}
                              />
                              <Form.Check
                                type="radio"
                                label="02:00 - 04:00 PM"
                                value="02:00 - 04:00 PM"
                                id="time4"
                                className="mb-3"
                                name="time_range"
                                onChange={handleTime}
                              />
                              <Form.Check
                                type="radio"
                                label="04:00 - 06:00 PM"
                                value="04:00 - 06:00 PM"
                                id="time5"
                                className="mb-3"
                                name="time_range"
                                onChange={handleTime}
                              />
                              <Form.Check
                                type="radio"
                                label="06:00 - 07:00 PM"
                                value="06:00 - 07:00 PM"
                                id="time6"
                                className="mb-3"
                                name="time_range"
                                onChange={handleTime}
                              />
                            </form>
                          </div>}
                        </div>
                        <div className="text-end mt-5">
                          <span className="error">{error3}</span>
                          <button
                            className="arrowBtn mt-3 me-2"
                            style={{ width: "120px" }}
                            onClick={() => setCurrentstep(1)}
                          >
                            Back
                          </button>
                          <button
                            className="arrowBtn mt-3 me-0"
                            style={{ width: "120px" }}
                            onClick={handleNext}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    ) : currentstep === 3 ? (
                      <div className="content">
                        <h1>How can we reach you?</h1>
                        <p>
                          You did it! Let us know a little about you and where
                          to send your quote.
                        </p>
                        <div className="seperator"></div>
                        <form className="py-5">
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                type="text"
                                placeholder="First Name"
                                className="mb-3"
                                value={firstName} onChange={e => setFirstName(e.target.value)} onInput={() => setError4("")}
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                placeholder="Last Name"
                                className="mb-3"
                                value={lastName} onChange={e => setLastName(e.target.value)} onInput={() => setError4("")}
                              />
                            </div>
                            <div className="col-md-12">
                              <input
                                type="text"
                                placeholder="Email Address"
                                className="mb-3"
                                value={email} onChange={e => setEmail(e.target.value)} onInput={() => setError4("")}
                              />
                            </div>
                            <div className="col-md-12">
                              <input
                                type="text"
                                placeholder="Contact Number"
                                className="mb-3"
                                value={contactNo} onChange={e => setContactNo(e.target.value)} onInput={() => setError4("")}
                              />
                            </div>
                            <div className="col-md-12">
                              <select name="" id="" className="mb-3" value={howDidHear} onChange={e => setHowDidHear(e.target.value)}>
                                <option value="0">How did you hear about us?</option>
                                <option value="Referred">Referred</option>
                                <option value="Repeat Client">Repeat Client</option>
                                <option value="Google">Google</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Influencer">Influencer</option>
                                <option value="Saw a Truck/Car">Saw a Truck/Car</option>
                                <option value="Twitter">Twitter</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            <div className="col-md-12">
                              <textarea
                                placeholder="Additional Information"
                                name=""
                                id=""
                                cols="30"
                                rows="5"
                                value={additionalInfo} onChange={e => setAdditionalInfo(e.target.value)} onInput={() => setError4("")}
                              ></textarea>
                            </div>
                          </div>
                        </form>







                        <div className="text-end mt-5">
                          {!showGoBackBtn && <><span className="error">{error4}</span>
                            <button
                              className="arrowBtn mt-3 me-2"
                              style={{ width: "120px" }}
                              onClick={() => setCurrentstep(2)}
                            >
                              Back
                            </button>
                            <button
                              className="arrowBtn mt-3 me-0"
                              style={{ width: "150px" }}
                              onClick={handleSubmit}
                            // data-toggle="modal" data-target="#exampleModal"
                            >

                              Submit
                              {showLoader && <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>}
                            </button></>}
                          {showGoBackBtn && <>
                            <Link to="/moving101Tips"><button
                              className="arrowBtn mt-3 mr-2"
                              style={{ width: "200px" }}
                            >
                              Moving 101 Tips
                            </button></Link>
                            <Link to="/"><button
                              className="arrowBtn mt-3 me-0"
                              style={{ width: "200px" }}
                            >
                              Go To Main Page
                            </button></Link>
                          </>}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <button id="tobeClicked" type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" style={{ display: 'none' }}>
        Launch demo modal
      </button>

      <div className="modal modal-lg fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/* <div className="modal-header">
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div> */}
            <div className="modal-body gif">
              <img src="../../imgs/axogif.gif" />
            </div>
            {/* <div className="text-center mb-4">
                              <Link to="/">
                                <button type="button" className="arrowBtn" data-dismiss="modal">Go Back to Main Page</button>
                              </Link>
                            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default GetQuote;
