import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import bgCovering from "../../imgs2/bg_localMovers.png";

function LocalMover() {
  const [movingFrom, setMovingFrom] = useState(false);
  const [movingTo, setMovingTo] = useState(false);

  const handleShowTO = () => {
    localStorage.setItem('showTO', JSON.stringify(false));
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="homeMain localMovers" style={{backgroundImage: `url(${bgCovering})`}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
            </div>
            <div className="col-12 my-auto searchMain">
              <div className="holder">
                <h1>
                  Let us Move you to <br /> Your New Life.
                </h1>
                <p className="my-3 pb-3">
                  Whether you're moving within the mainland or island, or from
                  either side. we've got you.
                </p>
              </div>
              <div className="row mx-0">
                <div className="col-md-10 px-0">
                  <div className="search">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="d-sm-flex gap-2">
                          <div
                            className={`customInput ${
                              movingFrom ? "focused" : null
                            }`}
                          >
                            <label htmlFor="movingFrom">Moving From</label>
                            <input
                              type="text"
                              id="movingFrom"
                              placeholder="Enter Current Address"
                              onFocus={() => setMovingFrom(true)}
                              onBlur={() => setMovingFrom(false)}
                            />
                          </div>
                          <div className="seperator text-center">
                            <div className="line"></div>
                          </div>
                          <div
                            className={`customInput ${
                              movingTo ? "focused" : null
                            }`}
                          >
                            <label htmlFor="movingFrom">Moving To</label>
                            <input
                              type="text"
                              id="movingFrom"
                              placeholder="Enter Destination"
                              onFocus={() => setMovingTo(true)}
                              onBlur={() => setMovingTo(false)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 text-md-end text-center my-auto">
                        <Link to="/get-a-quote">
                          <button onClick={handleShowTO}>
                            Get A Free Quote{" "}
                            <img src="/imgs/arrow-right.svg" alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 lagosMain">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                Let’s show you how to make your move in Lagos pleasing one
              </h1>
            </div>
            <div className="col-12">
              <div className="row flex-row-reverse">
                <div className="col-md-6">
                  <img
                    src="/imgs/building.png"
                    className="w-100 my-md-0 my-3"
                    alt=""
                  />
                </div>
                <div className="col-md-6">
                  <p className="mt-3">
                    When choosing the best Movers in Lagos, know Axopolitan
                    moving is the favorite choice take our time to understand
                    your needs and pain because we point. We are experts with a
                    proven track record of friendly, professional and timely
                    moving services. Let Us Create a Stress-Free Moving
                    Experience for You. Everyone knows moving can be one of
                    life's most stressful events, this is why we’re here to help
                    you manage your relocation stress. Too good to be true? Just
                    ask our customers who would refer us to friends and family
                    for their next move.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="keyPointsMain py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Why you should choose Axopolitan for your next move</h1>
              <h2>
                We're your clear favorite. You’ll enjoy several beneﬁts with the
                #1 Lagos movers
              </h2>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <img src="/imgs/key.png" className="w-100 h-100" alt="" />
            </div>
            <div className="col-md-6">
              <div className="point">
                <div className="d-flex gap-3 align-items-start">
                  <img
                    src="/imgs/top_rated.svg"
                    className="mt-0"
                    width={25}
                    alt=""
                  />
                  <h3>Top Rated</h3>
                </div>
                <p className="mt-3 ms-4">
                  We are top-rated movers in Lagos and, we have built a good
                  reputation by helping thousands of people move into new homes
                  and commercial premises seamlessly. From the experience we've
                  been able to attain, we’ve come to understand what customers
                  would want from a moving company and we tailor our services to
                  meet the needs of our clients where moving is concerned. Check
                  out our reviews to see what customers have had to say about us
                  over the years.
                </p>
              </div>
              <div className="point mt-5">
                <div className="d-flex gap-3 align-items-start">
                  <img
                    src="/imgs/customer_service.svg"
                    className="mt-0"
                    width={25}
                    alt=""
                  />
                  <h3>Customer Service</h3>
                </div>
                <p className="mt-3 ms-4">
                  From the moment you reach out to us, know you're fully backed
                  with a 5- star customer support team, we would go the extra
                  mile to ensure your moving experience is a pleasurable one
                  that rings a bell with Axopolitan Moving.
                </p>
              </div>
              <div className="point mt-5">
                <div className="d-flex gap-3 align-items-start">
                  <img
                    src="/imgs/professional.svg"
                    className="mt-0"
                    width={25}
                    alt=""
                  />
                  <h3>Caring, Professional Movers</h3>
                </div>
                <p className="mt-3 ms-4">
                  Every one of our movers is highly trained and cares about the
                  happiness of our customers. We have the ﬁnest people in the
                  moving and storage business, making us one of the best home
                  and commercial moving companies. Experience the difference
                  that years of service and years of perfecting the personalized
                  touch can mean for you and your family.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="justaMove py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-4">
              <h1>It's more than just a move</h1>
              <h2>There’s more to moving than moving. We do it all.</h2>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex gap-2">
                    <img src="/imgs/wrapping.svg" width={55} alt="" />
                    <h3>Wrapping and Packing</h3>
                  </div>
                  <p className="mt-2">
                    We ensure all furniture pieces are carefully protected, wrap
                    and pack mattresses in custom protection materials, Flat
                    screens wrapped and packed in special TV box or padded, wrap
                    couches and chairs in blankets and shrink wrap.
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="d-flex gap-2">
                    <img src="/imgs/qr_code.svg" width={45} alt="" />
                    <h3>Disassembly and Reassembly</h3>
                  </div>
                  <p className="mt-2">
                    Disassembling and assembling of bed frames, dining set,
                    Dismount and pack ﬂat-screen TVs. Disassembling Tables that
                    may need to be separated to move nicely.
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="d-flex gap-2">
                    <img src="/imgs/qr_code.svg" width={45} alt="" />
                    <h3>Packing</h3>
                  </div>
                  <p className="mt-2">
                    Hang and pack clothes in wardrobe boxes or custom boxes.
                  </p>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-12">
                  <h5 className="mb-0">
                    Input a few simple details and get your moving quote in just
                    four steps:
                  </h5>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-lg-3 col-sm-6">
                  <div className="step">
                    <img src="/imgs/step1.svg" alt="" />
                    <h3 className="my-3">Step 1</h3>
                    <p>
                      Provide your current address and where you're moving to.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="step">
                    <img src="/imgs/step2.svg" alt="" />
                    <h3 className="my-3">Step 2</h3>
                    <p>
                      Tick the box that corresponds with your current home size.
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="step">
                    <img src="/imgs/step3.svg" alt="" />
                    <h3 className="my-3">Step 3</h3>
                    <p>Choose the date you want to move.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="step">
                    <img src="/imgs/step4.svg" alt="" />
                    <h3 className="my-3">Step 4</h3>
                    <p>
                      Provide contact details and where we can send your quote.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-sm-flex gap-5 align-items-center">
                  <h4 className="mb-0">Get started with us today</h4>
                  <Link to="/get-a-quote">
                    <button className="arrowBtn mt-1" onChange={handleShowTO}>
                      Get A Free Quote{" "}
                      <img src="/imgs/arrow-right.svg" alt="" />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LocalMover;
