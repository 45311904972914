import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import bgImage from '../../imgs2/storage_banner.png';

function Storage() {
  const handleShowTO = bol => {
    localStorage.setItem('showTO', JSON.stringify(bol));
  }
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="homeMain storageMain" style={{backgroundImage: `url(${bgImage})`}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
            </div>
            <div className="col-12 my-auto searchMain">
              <h1>Moving & Storage Services that gives you peace of mind</h1>
              <p>
                Let Our storage experts take good care of your valuable goods
                while you travel or ﬁnd a new place
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 goodHands">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>Storage has never been this nice</h1>
              <p>
                Axopolitan moving and storage keeps you rest assured that your
                precious possessions are in good hands
              </p>
            </div>
            <div className="col-12 mt-4">
              <div className="d-flex justify-content-center gap-4 flex-sm-row flex-column">
                <Link to="/get-a-quote">
                  <button className="gradientBtn" onChange={() => handleShowTO(false)}>Get a Quote!</button>
                </Link>
                <Link to="/get-a-quote">
                  <button className="hollowBtn" onChange={() => handleShowTO(true)}>Get Consultation</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="storageNeeds">
        <div className="container-fluid px-0">
          <div className="row mx-0 bg-white">
            <div className="col-md-6 px-0">
              <img src="/imgs/storage_need.png" className="w-100" alt="" />
            </div>
            <div className="col-md-6 px-md-5 my-md-auto mt-3">
              <h1>We're here for all your storage needs!</h1>
              <p>
                Having troubles with where to keep your things while your
                travel, ﬁnd a new home or even renovate your house? Thanks to
                us! You can be sure to ﬁnd us ready to store your items safe
                regardless of how many they are. This means you can take a dream
                vacation or take the time to ﬁnd your dream home while your
                things stay safe and intact in our climate controlled
                warehouses.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="storageNeeds storeMain">
        <div className="container-fluid px-0">
          <div className="row mx-0 bg-white">
            <div className="col-md-6 px-0">
              <img src="/imgs/store.png" className="w-100 h-100" alt="" />
            </div>
            <div className="col-md-6 px-md-5 my-md-auto mt-3 content">
              <h1>Why store with us?</h1>
              <div className="d-flex gap-3 align-items-center mt-5">
                <img src="/imgs/star.svg" alt="" />
                <h2> A home away from home</h2>
              </div>
              <p>
                Having troubles with where to keep your things while your
                travel, ﬁnd a new home or even renovate your house? Thanks to
                us! You can be sure to ﬁnd us ready to store your items safe
                regardless of how many they are. This means you can take a dream
                vacation or take the time to ﬁnd your dream home while your
                things stay safe and intact in our climate controlled
                warehouses.
              </p>
              <div className="d-flex gap-3 align-items-center mt-4">
                <img src="/imgs/star.svg" alt="" />
                <h2> Tailor-Fit For Your Needs</h2>
              </div>
              <p>
                When looking for storage services, look no further. We won’t
                just store your things, we ensure we store your possessions
                exactly to your needs. Our team understands how people might
                want to be extra detailed with how they want their belongings to
                be stored and maintained, you may not ﬁnd this with other
                services. You can be rest assured that we will tailor-ﬁt your
                storage to your needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="bestMoving">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Best Moving & Storage Services In Lagos</h1>
              <p>
                From enquiry, Axopolitan’s experienced team of professional
                movers will organize a seamless moving and storage service for
                your home or ofﬁce, making the transition smooth, efﬁcient and
                easy. Our mission is to make your move a stress-free one!
              </p>
            </div>
          </div>
        </div>
        <div className="curvedDiv">
          <div className="curved"></div>
          <div className="container">
            <div className="row">
              <div className="col-12 text-end">
                <button className="getStartedBtn">Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Storage;
