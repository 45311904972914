import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <div className="d-sm-flex justify-content-between">
              <div>
                <div className="d-sm-flex gap-4">
                  <h1>Get Moving!</h1>
                  <a href="tel:07043748903">
                    <div className="d-flex gap-2 align-items-center">
                      <i className="fa-solid fa-phone"></i>
                      <span>07043748903</span>
                    </div>
                  </a>
                </div>
                <InputGroup className="mb-3">
                  <Form.Control placeholder="Enter your email address" />
                  <InputGroup.Text>
                    <i className="fa-solid fa-arrow-right"></i>
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div>
                <Link to="/get-a-quote">
                  <button className="getQuote">Get a Quote</button>
                </Link>
                <h3>FOLLOW US</h3>
                <div className="d-flex followUs gap-3 mt-3">
                  <a
                    href="https://www.instagram.com/axopolitanmoving"
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/axopolitanmoving"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a href="https://twitter.com/axopolitanmovin" target="_blank">
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.tiktok.com/@axopolitanmovers"
                    target="_blank"
                  >
                    <i className="fa-brands fa-tiktok"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <img src="/imgs/footer-logo.svg" alt="" />
            <h4>
              WE WOULD LOVE <br /> TO HEAR FROM YOU
            </h4>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-md-3">
                <h5>
                  OUR ADDRESS <i className="fa-solid fa-location-dot ms-2"></i>
                </h5>
                <p className="mb-2">AXOPOLITAN MOVING</p>
                <p className="mb-2">
                  The Mall, Suite 2 Women <br /> development center, oba ogunji
                  <br /> road pencinema Lagos
                </p>
                <p>
                  <a href="mailto:Info@axopolitan.com" className="link">
                    Info@axopolitan.com
                  </a>
                </p>
                <p>
                  <a href="www.axopolitan.com" className="link">
                    www.axopolitan.com
                  </a>
                </p>
              </div>
              <div className="col-md-3">
                <h5>Quick Links</h5>
                <p>
                  <Link to="/local-movers" className="link">
                    Moving
                  </Link>
                </p>
                <p className="link">About us</p>
                <p>
                  <Link to="/blogs" className="link">
                    Blog
                  </Link>
                </p>
                <p>
                  <Link to="/moving101Tips" className="link">
                    Moving Tips
                  </Link>
                </p>
                <p>
                  <Link to="/book" className="link">
                    Book Virtual Survey
                  </Link>
                </p>
                <p className="link">Moving FAQ</p>
              </div>
              <div className="col-md-3">
                <h5>Help</h5>
                <p>
                  <Link to="/contact-us" className="link">
                    Contact
                  </Link>
                </p>
                <p>
                  <Link to="/get-a-quote" className="link">
                    Get a Quote
                  </Link>
                </p>
                <p className="link">Terms & Conditions</p>
              </div>
            </div>
          </div>
          <div className="col-12 py-3">
            <h4 className="copyRight">
              c 2022 Axopolitan Moving. All Rights Reserved.
            </h4>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
