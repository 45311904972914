import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import bgImag from "../../imgs/movers_banner.png";

function CommercialMovers() {
  const handleShowTO = bol => {
    localStorage.setItem('showTO', JSON.stringify(bol));
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <div className="homeMain storageMain commercialMoverMain" style={{backgroundImage: `url(${bgImag})`}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
            </div>
            <div className="col-12 my-auto searchMain">
              <h1>We Know Work Can Be Stressful. Moving Shouldn't Be.</h1>
              <p>Let Our expert movers give your ofﬁce a successful move</p>
              <div className="text-center mt-2">
                <Link to="/get-a-quote">
                  <button className="arrowBtn mt-3" onClick={() => handleShowTO(true)}>
                    Book Consultation <img src="/imgs/arrow-right.svg" alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 takeBusiness  ">
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>We'll take your business into it's new home</h1>
              <p className="mt-4">
                Got a new space for the business? Axopolitan's commercial movers
                have exactly what you need to get started on the right foot.
              </p>
            </div>
            <div className="col-12 mt-4">
              <div className="d-flex justify-content-center gap-4 flex-sm-row flex-column">
                <Link to="/get-a-quote">
                  <button className="gradientBtn" onClick={() => handleShowTO(false)}>Get a Quote!</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="storageNeeds commercialTrust">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 px-0">
              <img src="/imgs/commercial_trust.png" className="w-100" alt="" />
            </div>
            <div className="col-md-6 px-md-5 my-md-auto mt-3">
              <h1 className="mb-3">Commercial movers you can trust.</h1>
              <p>
                There's a reason we're the most preferred where it concerns
                ofﬁce moves. Our years of moving experience combined with an
                affordable, stress-free experience makes for the best commercial
                moving experience you can get out there. Whether you’re moving
                your business from a 20th ﬂoor or an entire building, Axopolitan
                is the top-notch commercial moving company for businesses of all
                sizes.
              </p>
              <Link to="/get-a-quote">
                <button className="gradientBtn" onClick={() => handleShowTO(true)}>Book Consultation</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CommercialMovers;
