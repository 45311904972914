import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,
  SubMenu,
} from "react-pro-sidebar";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";

function Header() {
  const [state, setState] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const location = useLocation();

  useEffect(() => {
    $(`div .highlight`).removeClass("highlight");
    if (location.pathname === "/contact-us") {
      setState(true);
    }
  }, []);
  const { collapseSidebar } = useProSidebar();

  const handleShowTO = (bol) => {
    localStorage.setItem("showTO", JSON.stringify(bol));
  };

  const handleShow = (e) => {
    setSearchTxt("");
    $("#formSearch").toggle(50);
    $("#icon").toggle(50);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTxt]);

  const handleSearch = () => {
    var searchTerm = $("#search").val();
    if (searchTerm.length === 0) {
      $(`.highlight`).removeClass("highlight");
      return;
    }
    $(`.highlight`).removeClass("highlight");
    // if(searchTerm.length > 2) {
    //   $(`h1:contains('${searchTerm}')`).addClass("highlight");
    //   $(`h2:contains('${searchTerm}')`).addClass("highlight");
    //   $(`h3:contains('${searchTerm}')`).addClass("highlight");
    //   $(`h4:contains('${searchTerm}')`).addClass("highlight");
    //   $(`h5:contains('${searchTerm}')`).addClass("highlight");
    //   $(`h6:contains('${searchTerm}')`).addClass("highlight");
    //   $(`p:contains('${searchTerm}')`).addClass("highlight");
    //   $(`button:contains('${searchTerm}')`).addClass("highlight");
    //   $(`a:contains('${searchTerm}')`).addClass("highlight");
    // } else {
    //   $(`div:contains('${searchTerm}')`).addClass("highlight");
    // }
    // if(searchTerm.length === 0) {
    //   $(`div:contains('${searchTerm}')`).removeClass("highlight");
    // }
    if (searchTerm.length > 0) {
      if ($(`div h1:contains('${searchTerm}')`))
        $(`div h1:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div h2:contains('${searchTerm}')`))
        $(`div h2:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div h3:contains('${searchTerm}')`))
        $(`div h3:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div h4:contains('${searchTerm}')`))
        $(`div h4:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div h5:contains('${searchTerm}')`))
        $(`div h5:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div h6:contains('${searchTerm}')`))
        $(`div h6:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div p:contains('${searchTerm}')`))
        $(`div p:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div h1:contains('${searchTerm}')`))
        $(`div span:contains('${searchTerm}')`).addClass("highlight");
      if ($(`div button:contains('${searchTerm}')`))
        $(`div button:contains('${searchTerm}')`).addClass("highlight");
    }

    // const words = searchTerm.split(" ");
    // let sentCase = words.map((word) => {
    //     return word[0].toUpperCase() + word.substring(1);
    // }).join(" ");
    // let sentCase = searchTerm.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    let sentCase = toTitleCase(searchTerm);
    let upperCase = searchTerm.toUpperCase();
    let lowerCase = searchTerm.toLowerCase();
    let arr = [searchTerm, sentCase, upperCase, lowerCase];
    for (let i = 0; i < arr.length; i++) {
      if ($(`div h1:contains('${arr[i]}')`))
        $(`div h1:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div h2:contains('${arr[i]}')`))
        $(`div h2:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div h3:contains('${arr[i]}')`))
        $(`div h3:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div h4:contains('${arr[i]}')`))
        $(`div h4:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div h5:contains('${arr[i]}')`))
        $(`div h5:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div h6:contains('${arr[i]}')`))
        $(`div h6:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div p:contains('${arr[i]}')`))
        $(`div p:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div h1:contains('${arr[i]}')`))
        $(`div span:contains('${arr[i]}')`).addClass("highlight");
      if ($(`div button:contains('${arr[i]}')`))
        $(`div button:contains('${arr[i]}')`).addClass("highlight");
    }
  };

  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <header>
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-5 align-items-center">
            <Link to="/">
              <img src="/imgs/logo.png" className="logo" alt="" />
            </Link>
            <a href="tel:07043748903">
              <div className="d-md-flex gap-2 align-items-center d-none">
                <i className="fa-solid fa-phone"></i>
                <span>07043748903</span>
              </div>
            </a>
          </div>
          {state && (
            <Link to="/get-a-quote">
              <button
                className="getQuote mt-1"
                onClick={() => handleShowTO(false)}
              >
                Get a Quote
              </button>
            </Link>
          )}
          <div className="d-flex gap-5 align-items-center">
            {/* <div className="seachBox">
                <span style={{float: 'left'}}><input type="text" className="form-control form-control-sm" placeholder="Enter text to search..." value={searchTxt} onChange={e => setSearchTxt(e.target.value0)} /></span>
                  <i className="fa-solid fa-magnifying-glass"></i>
              </div> */}
            <form id="formSearch">
              <div className="input-group">
                <input
                  id="search"
                  type="text"
                  className="form-control search"
                  value={searchTxt}
                  onChange={(e) => setSearchTxt(e.target.value)}
                  name="email"
                  placeholder="Enter text to search..."
                />
                <span className="input-group-addon" onClick={handleShow}>
                  <i className="fa-solid fa-magnifying-glass fa-sm"></i>
                </span>
              </div>
            </form>
            <i
              className="fa-solid fa-magnifying-glass"
              id="icon"
              onClick={handleShow}
            ></i>

            <i
              className="fa-solid fa-bars-staggered"
              style={{ cursor: "pointer" }}
              onClick={() => collapseSidebar()}
            ></i>
          </div>
        </div>
      </header>
      <div style={{ display: "flex", height: "100%" }}>
        <Sidebar defaultCollapsed collapsedWidth="0px" width="300px">
          <div className="text-end">
            <img
              style={{ cursor: "pointer" }}
              src="/imgs/close.svg"
              onClick={() => collapseSidebar()}
              width="50"
              alt=""
            />
          </div>
          <img src="/imgs/logo.png" className="logo" alt="" />
          <hr />
          <div
            id="high"
            className="d-flex gap-2 align-items-center justify-content-center mb-3 phone"
          >
            <i className="fa-solid fa-phone"></i>
            <span>07043748903</span>
          </div>
          <Link to="/get-a-quote">
            <button className="getQuote" onClick={() => handleShowTO(false)}>
              Get a Quote
            </button>
          </Link>
          <br />
          <Link to="/book">
            <button className="bookSurvey" onClick={() => handleShowTO(true)}>
              Book Virtual Survey
            </button>
          </Link>
          <Menu>
            <SubMenu label="Moving">
              <MenuItem>
                {" "}
                <Link to="/local-movers">Local Moving</Link>{" "}
              </MenuItem>
              <MenuItem>
                {" "}
                <Link to="/commercial-movers">Commercial Moving</Link>{" "}
              </MenuItem>
            </SubMenu>
            <MenuItem>
              {" "}
              <Link to="/storage">Storage</Link>{" "}
            </MenuItem>
            {/* <MenuItem>
              {" "}
              <Link to="/get-a-quote">Get A Free Quote</Link>
            </MenuItem>
            <MenuItem> Book Consultation</MenuItem> */}
            <MenuItem>
              <Link to="/blogs">Blog</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/contact-us">Contact Us</Link>
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
}

export default Header;
