import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import bg from '../../imgs2/home_banner.png'
import $ from 'jquery';

function Home() {
  const [movingFrom, setMovingFrom] = useState(false);
  const [movingTo, setMovingTo] = useState(false);
  const [movingFromVal, setMovingFromVal] = useState("");
  const [movingToVal, setMovingToVal] = useState("");

  const [moveCard1, setMoveCard1] = useState(false);
  const [moveCard2, setMoveCard2] = useState(false);
  const [moveCard3, setMoveCard3] = useState(false);
  const [companyCard, setcompanyCard] = useState(true);
  const [companyCard1, setcompanyCard1] = useState(false);
  const [companyCard2, setcompanyCard2] = useState(false);
  const [companyCard3, setcompanyCard3] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    $(`div .highlight`).removeClass("highlight");
  }, [])

  const handleShowTO = bol => {
    localStorage.setItem('showTO', JSON.stringify(bol));
    
    localStorage.setItem('movingFromVal', JSON.stringify(movingFromVal));
    localStorage.setItem('movingToVal', JSON.stringify(movingToVal));
  }

  const handleMouseOver = k => {
    if(k === "moveCard1") setMoveCard1(true);
    if(k === "moveCard2") setMoveCard2(true);
    if(k === "moveCard3") setMoveCard3(true);
  }

  const handleMouseOut = k => {
    if(k === "moveCard1") setMoveCard1(false);
    if(k === "moveCard2") setMoveCard2(false);
    if(k === "moveCard3") setMoveCard3(false);
  }

  // useEffect(() => {
  //   console.log(1);
  // }, [])

  return (
    <>
      <div className="homeMain" style={{backgroundImage: `url(${bg})`}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Header />
            </div>
            <div className="col-12 my-auto searchMain">
              <h1>
                A Truly Moving <br /> Experience
              </h1>
              <p id="highw">Moving day should always be a celebration!</p>
              <div className="row mx-0">
                <div className="col-md-10 px-0">
                  <div className="search">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="d-sm-flex gap-2">
                          <div
                            className={`customInput ${
                              movingFrom ? "focused" : null
                            }`}
                          >
                            <label htmlFor="movingFrom">Moving From</label>
                            <input
                              type="text"
                              id="movingFrom"
                              placeholder="Enter Current Address"
                              onFocus={() => setMovingFrom(true)}
                              onBlur={() => setMovingFrom(false)}
                              value={movingFromVal} onChange={e => setMovingFromVal(e.target.value)}
                            />
                          </div>
                          <div className="seperator text-center">
                            <div className="line"></div>
                          </div>
                          <div
                            className={`customInput ${
                              movingTo ? "focused" : null
                            }`}
                          >
                            <label htmlFor="movingFrom">Moving To</label>
                            <input
                              type="text"
                              id="movingFrom"
                              placeholder="Enter Destination"
                              onFocus={() => setMovingTo(true)}
                              onBlur={() => setMovingTo(false)}
                              value={movingToVal} onChange={e => setMovingToVal(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 text-md-end text-center my-auto">
                        <Link to="/get-a-quote">
                          <button onClick={() => handleShowTO(false)}>
                            Get A Free Quote{" "}
                            <img src="/imgs/arrow-right.svg" alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="secondSection py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pb-5">
              <div className="justAMove">
                <h1>Axopolitan. It's more than just a move.</h1>
                <p>When moving day comes, you know we've got your back</p>
              </div>
            </div>
            <div className="col-12">
              <div className="row gx-md-5">
                <Link to="/local-movers" className="col-md-4">
                    <div
                      className={`moveCard ${moveCard1 ? "active" : ""}`}
                      onMouseOver={() => handleMouseOver("moveCard1")}
                      onMouseOut={() => handleMouseOut("moveCard1")}
                      // onClick={() => [
                      //   setMoveCard1(true),
                      //   setMoveCard2(false),
                      //   setMoveCard3(false),
                      // ]}
                    >
                      {moveCard1 ? (
                        <img src="/imgs/local-moving.svg" width={100} alt="" />
                      ) : (
                        <img
                          src="/imgs/local-moving-blue.svg"
                          width={100}
                          alt=""
                        />
                      )}

                      <h1>
                        Local <br />
                        moving
                      </h1>
                      <p>
                        Moving within the island or mainland or from either side?
                        We're your favorite choice and we've got you!
                      </p>
                    </div>
                </Link>
                <Link to="/commercial-movers" className="col-md-4 mt-md-0 mt-3">
                  <div
                    className={`moveCard ${moveCard2 ? "active" : ""}`}
                    onMouseOver={() => handleMouseOver("moveCard2")}
                    onMouseOut={() => handleMouseOut("moveCard2")}
                    // onClick={() => [
                    //   setMoveCard1(false),
                    //   setMoveCard2(true),
                    //   setMoveCard3(false),
                    // ]}
                  >
                    {moveCard2 ? (
                      <img src="/imgs/comm-moving.svg" width={75} alt="" />
                    ) : (
                      <img src="/imgs/comm-moving-blue.svg" width={75} alt="" />
                    )}

                    <h1>
                      Commercial <br />
                      moving
                    </h1>
                    <p>
                      New achievements for the business?We're experts in ofﬁce
                      relocation and we won't loose a paper clip.
                    </p>
                  </div>
                </Link>

                <Link to="/storage" className="col-md-4 mt-md-0 mt-3">
                  <div
                    className={`moveCard ${moveCard3 ? "active" : ""}`}
                    onMouseOver={() => handleMouseOver("moveCard3")}
                    onMouseOut={() => handleMouseOut("moveCard3")}
                    // onClick={() => [
                    //   setMoveCard1(false),
                    //   setMoveCard2(false),
                    //   setMoveCard3(true),
                    // ]}
                  >
                    {moveCard3 ? (
                      <img src="/imgs/storage-services.svg" width={70} alt="" />
                    ) : (
                      <img
                        src="/imgs/storage-services-blue.svg"
                        width={70}
                        alt=""
                      />
                    )}

                    <h1>Moving and Storage services</h1>
                    <p>
                      Need more room for what you love? Need a space to keep
                      your stuffs while you Travel or ﬁnd a new home?
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="thirdSection py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 py-5">
              <h1>
                We're one of the best moving companies you'll ﬁnd in Lagos
              </h1>
              <p>You'll enjoy several beneﬁts by choosing us.</p>
            </div>
            <div className="col-12">
              <div className="row gx-md-5">
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div
                    className={`companyCard ${companyCard ? "active" : ""}`}
                    onClick={() => [
                      setcompanyCard(true),
                      setcompanyCard1(false),
                      setcompanyCard2(false),
                      setcompanyCard3(false),
                    ]}
                  >
                    <div className="imgDiv">
                      {companyCard ? (
                        <img
                          src="/imgs/affordable-white.svg"
                          width={100}
                          alt=""
                        />
                      ) : (
                        <img src="/imgs/affordable.svg" width={100} alt="" />
                      )}
                    </div>
                    {companyCard ? (
                      <>
                        <h1>
                          Simple and Affordable pricing for all move types
                        </h1>
                        <p>
                          Moving doesn't have to cost you a fortune. With our
                          simple and affordable pricing options, you'd get the
                          best value for your move at a fair price with no
                          hidden charges. We are a moving company that puts your
                          happiness as a top priority.
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mt-md-0 mt-3">
                  <div
                    className={`companyCard ${companyCard1 ? "active" : ""}`}
                    onClick={() => [
                      setcompanyCard(false),
                      setcompanyCard1(true),
                      setcompanyCard2(false),
                      setcompanyCard3(false),
                    ]}
                  >
                    <div className="imgDiv">
                      {companyCard1 ? (
                        <img
                          src="/imgs/reputation-white.svg"
                          width={100}
                          alt=""
                        />
                      ) : (
                        <img src="/imgs/reputation.svg" width={100} alt="" />
                      )}
                    </div>
                    {companyCard1 ? (
                      <>
                        <h1>Top rated & Great reputation </h1>
                        <p>
                          Over the years, we have assisted thousands move
                          seamlessly and have build a good reputation. We know
                          what customers need when moving day comes and we
                          provide just that. Sounds too good to be true? check
                          out our reviews and ask customers who would happily
                          refer their friends and family.
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mt-md-0 mt-3">
                  <div
                    className={`companyCard ${companyCard2 ? "active" : ""}`}
                    onClick={() => [
                      setcompanyCard(false),
                      setcompanyCard1(false),
                      setcompanyCard2(true),
                      setcompanyCard3(false),
                    ]}
                  >
                    <div className="imgDiv">
                      {companyCard2 ? (
                        <img src="/imgs/customer-white.svg" width={70} alt="" />
                      ) : (
                        <img src="/imgs/customer.svg" width={70} alt="" />
                      )}
                    </div>
                    {companyCard2 ? (
                      <>
                        <h1>Excellent customer service relation</h1>
                        <p>
                          From the moment you reach out to us, know you're fully
                          backed with a 5- star customer support team, we would
                          go the extra mile to ensure your moving experience is
                          a pleasurable one that rings a bell with Axopolitan
                          Moving
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mt-md-0 mt-3">
                  <div
                    className={`companyCard ${companyCard3 ? "active" : ""}`}
                    onClick={() => [
                      setcompanyCard(false),
                      setcompanyCard1(false),
                      setcompanyCard2(false),
                      setcompanyCard3(true),
                    ]}
                  >
                    <div className="imgDiv">
                      {companyCard3 ? (
                        <img src="/imgs/service-white.svg" width={80} alt="" />
                      ) : (
                        <img src="/imgs/service.svg" width={80} alt="" />
                      )}
                    </div>
                    {companyCard3 ? (
                      <>
                        <h1>Tailored moving service </h1>
                        <p>
                          Experience great ﬂexibility options with Axopolitan
                          moving. Whether you need a packing and unpacking
                          service, furniture disassembling and reassembling,
                          moving grand pianos or special crating for exporting
                          and whatever may pose as a nightmare with moving. Let
                          us know what you want and we'll tailor our service to
                          meet your needs.
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="fourthSection py-5">
        <div className="container">
          <div className="row gx-md-5">
            <div className="col-md-12">
              <h1>
                Let's create a stress-free moving <br />
                experience for you
              </h1>
              <h2>We'll help you navigate the stress of moving</h2>
            </div>
            <div className="col-md-8">
              <p className="mt-3">
                When you desire to have a seamless moving experience. You want
                Axopolitan Moving. We are the most ideal team in the business
                because we have the friendliest and most helpful movers who take
                their time to meet the desires of customers. Here's what you can
                expect:
              </p>
              <ul>
                <li>Highly trained and professional team.</li>
                <li>Excellent packing and unpacking service</li>
                <li>Extra careful protection on items</li>
                <li>Greater efﬁciency</li>
                <li>Customer service</li>
              </ul>
            </div>
            <div className="col-md-4">
              <img src="/imgs/experience.png" className="w-100 expImg" alt="" />
            </div>
            <div className="col-12">
              <Link to="/get-a-quote">
                <button className="arrowBtn" onClick={() => handleShowTO(false)}>
                  Get A Free Quote <img src="/imgs/arrow-right.svg" alt="" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <div className="row gx-md-5">
            <div className="col-md-12">
              <img src="../../imgs/reviews.png" className="reviewsImg" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
