import Footer from "../Layouts/Footer";
import Header from "../Layouts/Header";
import { useEffect } from "react";
import bg from '../../imgs2/home_banner.png'

function Monving101Tips() {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            <div className="homeMain tips" style={{backgroundImage: `url(${bg})`}}>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <Header />
                        </div>
                        <div className="col-12 my-auto searchMain">
                            <h1>Moving 101 Tips for your comfort</h1>
                            <p>
                            We know what it takes to move, and we've compiled some Moving Guides to help make your next big move more leisurely.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            <div className="container py-5 tips">
                <div className="row mb-0">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  m-0">
                        <img src="../../imgs/dog.jpg" className="img1" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 m-0 textTips">
                        <h1>Tips for your big move</h1>
                        <p>A lot of people would see moving as a hard task, but having the right perspective makes the difference. Relocating in Lagos multiplies the stress and complexities as compared to anywhere else in the nation. Just to say, once you have us on your side, it's good as seamless. Axopolitan moving has been known to alleviate stress when moving day comes. Here are some few tips, you can request a free consultation for more and our awesome representatives will be happy to discuss better with you.</p>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12 m-0 txt">
                        <h1>1. Stock Up On Moving Boxes</h1>
                        <p>Getting high-quality boxes is essential in ensuring your items are well protected during the moving process. We even advice getting them at least 3 weeks before your moving day. Call Axopolitan Moving at 07043748903 and we'll deliver quality boxes right to your door. Trust their strength to easily carry your heaviest and most fragile items, Axopolitan's box delivery also simplifies your move by eliminating the issue of how to get the all those boxes to your home in the first place.</p>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12 m-0 txt">
                        <h1>2. Choose an Available Moving Date Ahead</h1>
                        <p>Did you know that some days are better to move and sometimes less expensive to move on than others? That's right! Since some moving companies get booked over the weekend, they may tend to increase moving fees. If you can be flexible, we advise that you plan your move ahead and make a booking deposit even if you don't have a firm date yet. Do this and thank us later.</p>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12 m-0 txt">
                        <h1>3. Talk To Your Property Manager</h1>
                        <p>Most residential building property managers, landlords etc, expect their incoming and outgoing tenants will give them a heads-up well in advance of their upcoming move. This allows them to ensure everything is ready for all move-in/move-out procedures, as well as eliminate the surprise of an unexpectedly hectic day. This is especially important upon movein as it's often where you make your first impressions. Don't start off on the wrong foot.</p>  
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 m-0">
                        <img src="../../imgs/trucks.jpg" className="img2" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 m-0 textTips">
                        <h1>4. Why Use a Moving Company?</h1>
                        <p>Especially in a somewhat stressful city like Lagos, it's very important that you hire a moving company to ensure your property is safely moved into your new home. We understand that moving into a new home comes with a lot of expenses. However, including the hiring of a moving company into your overall moving budget provides added convenience and security for your most prized possessions.</p>
                        <p>Isn't moving hard enough already? And just think If you were to do it all yourself, how would you get your dining table, couches or cabinetry from the mainland to the island or from either side? And do you really want to take your risk on all those expensive items to hiring a truck from the roadside? Instead, trust a moving company in Lagos that's dedicated to moving Lagosians and their things as safely and stress-free as possible to their new homes.</p>
                    </div>
                </div>
                <div className="row py-4">
                    <div className="col-12 m-0 txt">
                        <h1>5. Picking the Right Moving Company In Lagos</h1>
                        <p>There are so many moving companies in Lagos, so how do you know which one is right for you? Here are a few questions to ask yourself when choosing moving companies.<br/><br/>
                            Are They Fully Certified and Insured? If a company doesn't have a proof that they're certified or have any insurance or guarantee policy, then there's a red flag.<br/><br/>
                            Have you checked Reviews? Online reviews and referral are some of the best ways to evaluate a potential moving company. Google and even just talking with friends or family that have recently moved in Lagos are great ways to gain insight on real time moving experiences.<br/><br/>
                            Do They Conduct Background Checks? Every company should run thorough background checks on their employees and be able to explain to you how they are completed. After all, these people will be in your home and handling your things. A great company will respect that and fully assure you that your belongings are in safe hands.</p>
                    </div>
                </div>
            </div>
            <Footer />
            </div>
        </>
    );
}

export default Monving101Tips;