import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/style.css";
import Storage from "./components/Storage/Storage";
import CommercialMovers from "./components/Movers/CommercialMovers";
import LocalMover from "./components/Movers/LocalMovers";
import ContactUs from "./components/ContactUs/ContactUs";
import GetQuote from "./components/GetQuote/GetQuote";
import Monving101Tips from "./components/Moving101Tips/moving101Tips";
import Blogs from "./components/Blogs/blogs";
import BlogDetails from "./components/Blogs/blogDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/storage" element={<Storage />} />
        <Route path="/commercial-movers" element={<CommercialMovers />} />
        <Route path="/local-movers" element={<LocalMover />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/get-a-quote" element={<GetQuote />} />
        <Route path="/book" element={<GetQuote />} />
        <Route path="/moving101Tips" element={<Monving101Tips />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogDetails" element={<BlogDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
